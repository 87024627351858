.slider input {
    --thumb-width: 50px;
    --thumb-height: 50px;
    --track-height: 3px;
    -webkit-appearance: none;
    height: var(--track-height);
    width: 100%;
    background-color: var(--blue);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    border: none;
    position: relative;
}

.slider input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: var(--thumb-width);
    height: var(--thumb-height);
}

.slider input::-moz-range-thumb {
    -webkit-appearance: none;
    background-color: transparent;
    width: var(--thumb-width);
    height: var(--thumb-height);
    border: none;
}
